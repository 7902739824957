import React from 'react';
import SolidSection from '../components/PageSections/Solid';
import ImageSection from '../components/PageSections/Image';
import Main from '../layouts/Main';
import WhereWhenInfo from '../components/WhereWhenInfo';
import { Button, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Link from 'next/link';
import Head from 'next/head';
import ChurchImage from '../../public/assets/church-front.jpg';
import CrossImage from '../../public/assets/cross.jpg';
import { CANONICAL_DOMAIN } from '../utils/constants';

export default function Home() {
  return (
    <Main header_title="We Preach Christ, the Hope of Glory" background_image={ChurchImage} headerHeight="50vh">
      <Head>
        <title>Fosterburg Baptist Church</title>
        <meta property="og:title" content="Fosterburg Baptist Church" key="title" />
        <link rel="canonical" href={CANONICAL_DOMAIN} />
        <meta name="description" content="Fosterburg Baptist Church is an independent, fundamental Baptist Church established in 1857"></meta>
        <meta name="keywords" content="church, baptist, fosterburg, christian, faith, Bible" />
      </Head>
      <div
        style={{
          textAlign: 'center',
          padding: 30,
          position: 'relative',
          zIndex: 1,
          background: 'white',
        }}
      >
        <Typography variant="h6" fontStyle="oblique">
          We would love to have you join us for worship this Sunday!
        </Typography>
        <div style={{ marginTop: 15 }}>
          <Link href="/what-to-expect" passHref>
            <Button variant="outlined" color="secondary" endIcon={<ArrowForwardIcon />}>
              What to Expect
            </Button>
          </Link>
        </div>
      </div>
      <ImageSection id="who-we-are" textColor="white" image={CrossImage} backgroundColor="#4a524b">
        <Typography variant="h4" component="h2">
          Who We Are
        </Typography>
        <div
          style={{
            padding: 50,
          }}
        >
          <Typography variant="subtitle1" paragraph>
            Our desire is to know God, glorify Him through worship, and love one another as Christ loves us. We unapologetically stand upon the truths of the
            Word of God in all of our preaching and teaching ministries.
          </Typography>
        </div>
      </ImageSection>
      <SolidSection id="where-when" color="white">
        <WhereWhenInfo />
      </SolidSection>
    </Main>
  );
}
